<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-detalle-CsIntSolicitud"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="z-index: 10000"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Detalle Solicitud</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-1">
            <ul class="nav nav-tabs" id="myTabDetalle" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Solicitudes"
                  data-toggle="tab"
                  href="#Solicitudes"
                  >Solicitud</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Solicitudes"
                  data-toggle="tab"
                  href="#Autorizacion"
                  >Autorizaciones</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabDetalleContent">
              <div class="tab-pane" id="Solicitudes">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                      <div class="card card-info card-outline collapsed-card">
                        <div class="card-header pt-2 pb-2">
                          <h3 class="card-title">
                            <i class="fas fa-clipboard-list"></i> Datos
                            Solicitud
                          </h3>
                          <div class="card-tools">
                            <span
                              class="badge"
                              :class="
                                data_solicitud.estado == 1
                                  ? 'badge-warning'
                                  : data_solicitud.estado == 2
                                  ? 'badge-success'
                                  : data_solicitud.estado == 3
                                  ? 'badge-primary'
                                  : data_solicitud.estado == 4
                                  ? 'badge-danger'
                                  : data_solicitud.estado == 5
                                  ? 'badge-primary'
                                  : data_solicitud.estado == 6
                                  ? 'badge-success'
                                  : 'badge-danger'
                              "
                              >{{ data_solicitud.nEstado }}</span
                            >
                            <button
                              type="button"
                              data-card-widget="collapse"
                              data-toggle="tooltip"
                              title="Collapse"
                              class="btn btn-tool"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>N° Solicitud:</b></label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >{{ data_solicitud.id }}</span
                            >
                          </div>
                          <div class="row">
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>Fecha Inicio:</b></label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >{{
                                data_solicitud.det_solicitudes &&
                                data_solicitud.det_solicitudes[0]
                                  ? data_solicitud.det_solicitudes[0].fecha_ini
                                  : ""
                              }}</span
                            >
                          </div>
                          <div class="row">
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>Fecha Fin:</b></label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >{{
                                data_solicitud.det_solicitudes &&
                                data_solicitud.det_solicitudes[0]
                                  ? data_solicitud.det_solicitudes[0].fecha_fin
                                  : ""
                              }}</span
                            >
                          </div>
                          <div
                            class="row"
                            v-if="data_solicitud.tipo_transporte !== 1"
                          >
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>Gerente De Area:</b></label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >{{
                                data_solicitud.gerente_area
                                  ? data_solicitud.gerente_area.nombres.nombres
                                  : ""
                              }}</span
                            >
                          </div>
                          <div
                            class="row"
                            v-if="data_solicitud.tipo_transporte !== 1"
                          >
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              >Tipo Servicio:</label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                            >
                              {{
                                data_solicitud.det_solicitudes &&
                                data_solicitud.det_solicitudes[0]
                                  ? data_solicitud.det_solicitudes[0]
                                      .tipoServicio
                                  : ""
                              }}
                            </span>
                          </div>
                          <div
                            class="row"
                            v-if="data_solicitud.tipo_transporte !== 1"
                          >
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              >Bloque:</label
                            >

                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                            >
                              {{
                                data_solicitud.bloque
                                  ? data_solicitud.bloque.nombre
                                  : ""
                              }}
                            </span>
                          </div>
                          <div
                            class="row"
                            v-if="data_solicitud.tipo_transporte !== 1"
                          >
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              >Sitio:</label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                            >
                              {{
                                data_solicitud.sitio
                                  ? data_solicitud.sitio.nombre
                                  : ""
                              }}
                            </span>
                          </div>
                          <div class="row">
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>Justificación: </b></label
                            >
                            <span class="col-md-8 col-sm-8 col-xs-6 text-right"
                              ><button
                                type="button"
                                class="btn bg-gradient-danger btn-xs"
                                data-toggle="modal"
                                data-target="#ModalJustificacion"
                              >
                                <i class="fas fa-file-signature"></i></button
                            ></span>
                          </div>
                          <div class="row">
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>Observaciones: </b></label
                            >
                            <span class="col-md-8 col-sm-8 col-xs-6 text-right"
                              ><button
                                type="button"
                                class="btn bg-gradient-warning btn-xs"
                                data-toggle="modal"
                                data-target="#ModalObservacion"
                              >
                                <i class="fas fa-file-signature"></i></button
                            ></span>
                          </div>
                          <div class="row">
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              ><b>Tipo Solicitud:</b></label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                              >{{ data_solicitud.tipoSolicitud }}</span
                            >
                          </div>
                          <div
                            class="row"
                            v-if="data_solicitud.tipo_solicitud == 2"
                          >
                            <label for="" class="col-md-4 col-sm-4 col-xs-6">
                              Tipo De Emergencia:
                            </label>
                            <div>
                              <span
                                id=""
                                class="col-md-8 col-sm-8 col-xs-6 text-right"
                                >{{ data_solicitud.tipoEmergencia }}</span
                              >
                            </div>
                          </div>
                          <div class="row">
                            <label for="" class="col-md-4 col-sm-4 col-xs-6">
                              Fecha:
                            </label>
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                            >
                              {{ data_solicitud.fecha }}
                            </span>
                          </div>
                          <div
                            class="row"
                            v-if="data_solicitud.tipo_transporte !== 1"
                          >
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              >Usuario:</label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                            >
                              {{
                                data_solicitud.user
                                  ? data_solicitud.user.name
                                  : ""
                              }}
                            </span>
                          </div>
                          <div
                            class="row"
                            v-if="data_solicitud.tipo_transporte !== 1"
                          >
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              >Correo:</label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                            >
                              {{
                                data_solicitud.user
                                  ? data_solicitud.user.email
                                  : ""
                              }}
                            </span>
                          </div>
                          <div
                            class="row"
                            v-if="data_solicitud.tipo_transporte !== 1"
                          >
                            <label for="" class="col-md-4 col-sm-4 col-xs-6"
                              >Celular:</label
                            >
                            <span
                              id=""
                              class="col-md-8 col-sm-8 col-xs-6 text-right"
                            >
                              {{
                                data_solicitud.user
                                  ? data_solicitud.user.cel
                                  : ""
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Datos Ruta -->
                    <div class="col-md-6 col-sm-12 col-xs-12">
                      <div class="card card-info card-outline collapsed-card">
                        <div class="card-header pt-2 pb-2">
                          <h3 class="card-title">
                            <i class="fas fa-route"></i> Detalle Ruta
                          </h3>
                          <div class="card-tools">
                            <button
                              type="button"
                              data-card-widget="collapse"
                              data-toggle="tooltip"
                              title="Collapse"
                              class="btn btn-tool"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body table-responsive p-0">
                          <table
                            class="table table-striped table-hover table-sm team-roster-table"
                            style="font-size: 0.85em"
                          >
                            <thead class="bg-light">
                              <tr>
                                <th class="text-center">Ruta</th>
                                <th class="text-center">Nombre De Contacto</th>
                                <th class="text-center">Teléfono</th>
                                <th class="text-center">Correo</th>
                                <th class="text-center">Dirección</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="detRuta in data_solicitud.solicitudes_rutas"
                                :key="detRuta.id"
                              >
                                <td class="text-center">
                                  <span
                                    class="col-sm-6 description-header"
                                    v-if="detRuta.nombre"
                                  >
                                    {{ detRuta.nombre }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  <span
                                    class="col-sm-6 description-header"
                                    v-if="detRuta.nombre_contacto"
                                  >
                                    {{ detRuta.nombre_contacto }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  <span
                                    class="col-sm-6 description-header"
                                    v-if="detRuta.telefono_contacto"
                                  >
                                    {{ detRuta.telefono_contacto }}</span
                                  >
                                </td>
                                <td class="text-center">
                                  <span
                                    class="col-sm-6 description-header"
                                    v-if="detRuta.email_contacto"
                                  >
                                    {{ detRuta.email_contacto }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  <span
                                    class="col-sm-6 description-header"
                                    v-if="detRuta.direccion"
                                  >
                                    {{ detRuta.direccion }}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- Datos Vehículo / Equipo-->
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="card card-info card-outline collapsed-card">
                        <div class="card-header pt-2 pb-2">
                          <h3 class="card-title">
                            <i class="fas fa-truck"></i> Datos Vehículo/Equipo
                          </h3>
                          <div class="card-tools">
                            <button
                              type="button"
                              data-card-widget="collapse"
                              data-toggle="tooltip"
                              title="Collapse"
                              class="btn btn-tool"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body table-responsive p-0">
                          <table
                            class="table table-striped table-hover table-sm team-roster-table"
                            style="font-size: 0.85em"
                          >
                            <thead class="bg-light">
                              <tr>
                                <th class="text-center">Tipo Servicio</th>
                                <th class="text-center">Vehículo/Equipo</th>
                                <th class="text-center">
                                  Cantidad Vehículos Solicitados
                                </th>
                                <th class="text-center">
                                  Tiempo Requerido del Servicio (Dias)
                                </th>
                                <th class="text-center">
                                  Descripción del material o equipos
                                </th>
                                <th class="text-center">Cantidad material</th>
                                <th class="text-center">
                                  Largo x Ancho x Alto (MTS)
                                </th>
                                <th class="text-center">Peso(Kg)</th>
                                <th class="text-center">Radio de izaje(Mts)</th>
                                <th class="text-center">Observaciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="det in data_solicitud.det_solicitudes"
                                :key="det.id"
                              >
                                <td class="text-center">
                                  {{ det.tipoServicio }}
                                </td>
                                <td class="text-center">
                                  <span>
                                    {{
                                      det.tipo_servicio == 1
                                        ? det.tipo_vehiculo
                                          ? det.tipo_vehiculo.nombre
                                          : ""
                                        : det.nTipoEquipo
                                    }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  {{
                                    data_solicitud.det_solicitudes
                                      ? det.cantidad_vh_equi
                                      : ""
                                  }}
                                </td>
                                <td class="text-center">
                                  {{ det.tiempo_serv }}
                                </td>
                                <td class="text-center">
                                  {{ det.descripcion_material }}
                                </td>
                                <td class="text-center">
                                  {{ det.cantidad_material }}
                                </td>
                                <td class="text-center">
                                  {{ det.largo }} x {{ det.ancho }} x
                                  {{ det.alto }}
                                </td>
                                <td class="text-center">
                                  {{ det.peso }}
                                </td>
                                <td class="text-center">
                                  {{ det.radio_izaje }}
                                </td>
                                <td class="text-center">
                                  {{ det.observaciones }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- Datos Contables -->
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div
                        class="card card-info card-outline collapsed-card m-0"
                      >
                        <div class="card-header pt-2 pb-2">
                          <h3 class="card-title">
                            <i class="fas fa-chart-line"></i> Datos contables
                          </h3>
                          <div class="card-tools">
                            <button
                              type="button"
                              data-card-widget="collapse"
                              data-toggle="tooltip"
                              title="Collapse"
                              class="btn btn-tool"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="card-body table-responsive p-0">
                          <table
                            class="table table-striped table-hover table-sm team-roster-table"
                            style="font-size: 0.85em"
                          >
                            <thead class="bg-light">
                              <tr>
                                <th class="text-center">Area</th>
                                <th class="text-center">OPEX/CAPEX</th>
                                <th class="text-center">Dueño AFE</th>
                                <th class="text-center">Tipo Presupuesto</th>
                                <th class="text-center">CECO/AFE/G&A</th>
                                <th class="text-center">Porcentaje</th>
                                <th class="text-center">
                                  Activo de producción
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="detCont in data_solicitud.solicitudes_presupuestos"
                                :key="detCont.id"
                              >
                                <td class="text-center">
                                  <span>
                                    {{ detCont.nArea }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  <span>
                                    {{ detCont.opexCapex }}
                                  </span>
                                </td>

                                <td class="text-center">
                                  {{ detCont.responsable.nombres }}
                                  {{ detCont.responsable.apellidos }}
                                </td>
                                <td class="text-center">
                                  <span>
                                    {{ detCont.tipoPresupuesto }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  <span>
                                    {{ detCont.ceco_afe.codigo_contable }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  {{ detCont.porcentaje }}%
                                </td>
                                <td class="text-center">
                                  {{ detCont.activoProduccion }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row justify-content-md-center"
                    v-if="data_solicitud.tipo_transporte == 2"
                  >
                    <div class="col-md-12">
                      <div class="card card-info">
                        <div class="card-header">
                          <h3 class="card-title">Vehículo y Conductor</h3>
                        </div>
                        <div class="card-body">
                          <!-- Vehículo -->
                          <div class="row">
                            <div
                              class="col-sm-6 d-flex align-items-stretch flex-column"
                              v-if="data_solicitud.vehiculo_id != null"
                            >
                              <div class="card bg-white d-flex flex-fill">
                                <div
                                  class="card-header text-muted border-bottom-0"
                                >
                                  Vehículo
                                </div>
                                <div class="card-body pt-0">
                                  <div class="row">
                                    <div
                                      :class="
                                        data_solicitud.vehiculo.link_fotografia
                                          ? 'col-md-7'
                                          : 'col-md-10'
                                      "
                                    >
                                      <h2 class="lead">
                                        <b>{{
                                          data_solicitud.vehiculo.placa
                                        }}</b>
                                      </h2>
                                      <p
                                        class="text-muted text-sm"
                                        v-if="data_solicitud.transportadora"
                                      >
                                        <b>Transportadora: </b>
                                        {{
                                          data_solicitud.transportadora
                                            .razon_social
                                        }}
                                      </p>
                                      <ul class="ml-4 mb-0 fa-ul text-muted">
                                        <li class="small">
                                          <span class="fa-li"
                                            ><i class="fas fa-lg fa-car"></i
                                          ></span>
                                          Tipo Vehículo:
                                          {{
                                            data_solicitud.tipo_vehiculo.nombre
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                    <div
                                      class="col-md-5 text-center"
                                      v-if="
                                        data_solicitud.vehiculo.link_fotografia
                                      "
                                    >
                                      <img
                                        :src="
                                          uri_docs +
                                          data_solicitud.vehiculo
                                            .link_fotografia
                                        "
                                        alt="Vehículo"
                                        class="img-bordered img-rounded img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-else
                              class="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column"
                            >
                              <div
                                class="small-box bg-secondary"
                                style="min-height: 100px"
                              >
                                <div class="inner">
                                  <h4>No hay vehiculo</h4>
                                </div>
                                <div class="icon">
                                  <i class="fas fa-car"></i>
                                </div>
                              </div>
                            </div>
                            <!-- Conductor -->
                            <div
                              class="col-sm-6 d-flex align-items-stretch flex-column"
                              v-if="data_solicitud.conductor_id != null"
                            >
                              <div class="card bg-white d-flex flex-fill">
                                <div
                                  class="card-header text-muted border-bottom-0"
                                >
                                  Conductor
                                </div>
                                <div class="card-body pt-0">
                                  <div class="row">
                                    <div
                                      :class="
                                        data_solicitud.conductor.link_fotografia
                                          ? 'col-md-7'
                                          : 'col-md-10'
                                      "
                                    >
                                      <h2 class="lead">
                                        <b
                                          >{{
                                            data_solicitud.conductor.nombres
                                          }}
                                          {{
                                            data_solicitud.conductor.apellidos
                                          }}
                                        </b>
                                      </h2>
                                      <p
                                        v-if="data_solicitud.encuesta"
                                        class="text-muted text-sm"
                                      >
                                        <b>Calificación: </b>
                                        {{
                                          data_solicitud.encuesta.calificacion
                                        }}
                                        <i class="fas fa-star"></i>
                                      </p>
                                      <p v-else class="text-muted text-sm">
                                        <b>Calificación: </b>
                                        Sin calificación
                                      </p>
                                      <p class="text-muted text-sm">
                                        <b>Documento: </b>
                                        {{
                                          data_solicitud.conductor
                                            .numero_documento
                                        }}
                                      </p>
                                      <ul class="ml-4 mb-0 fa-ul text-muted">
                                        <li class="small">
                                          <span class="fa-li"
                                            ><i
                                              class="fas fa-lg fa-envelope"
                                            ></i
                                          ></span>
                                          E-mail:
                                          {{ data_solicitud.conductor.email }}
                                        </li>
                                        <li class="small">
                                          <span class="fa-li"
                                            ><i class="fas fa-lg fa-mobile"></i
                                          ></span>
                                          Celular Principal:
                                          {{
                                            data_solicitud.conductor
                                              .celular_principal
                                          }}
                                        </li>
                                        <li class="small">
                                          <span class="fa-li"
                                            ><i class="fas fa-lg fa-phone"></i
                                          ></span>
                                          Celular Alterno:
                                          {{
                                            data_solicitud.conductor
                                              .celular_alterno
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                    <div
                                      class="col-md-5 text-center"
                                      v-if="
                                        data_solicitud.conductor.link_fotografia
                                      "
                                    >
                                      <img
                                        :src="
                                          uri_docs +
                                          data_solicitud.conductor
                                            .link_fotografia
                                        "
                                        alt="Conductor"
                                        class="img-bordered img-rounded img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-else
                              class="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column"
                            >
                              <div
                                class="small-box bg-secondary"
                                style="min-height: 100px"
                              >
                                <div class="inner">
                                  <h4>No hay conductor</h4>
                                </div>
                                <div class="icon">
                                  <i class="fas fa-user"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Autorización -->
              <div class="tab-pane" id="Autorizacion">
                <div
                  class="card-body p-0"
                  v-if="data_solicitud && data_solicitud.autorizacion && data_solicitud.autorizacion.length > 0"
                >
                  <table
                    class="table table-striped table-hover table-sm team-roster-table"
                    style="font-size: 0.85em;"
                  >
                    <thead class="bg-light">
                      <tr>
                        <th>Tipo</th>
                        <th>Funcionario</th>
                        <th>Funcionario Que Autoriza</th>
                        <th>Fecha Autorizo</th>
                        <th>Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="autor in data_solicitud.autorizacion"
                        :key="autor.id"
                      >
                        <td>
                          {{ autor.tipoAutorizacion }}
                        </td>
                        <td>
                          {{ autor.user.name }}
                        </td>
                        <td v-if="autor.user_autoriza">
                          {{ autor.user_autoriza.name }}
                        </td>
                        <td v-else></td>
                        <td>{{ autor.fecha_autorizacion }}</td>
                        <td>
                          <span
                            class="badge"
                            :class="
                              autor.estado == 1
                                ? 'badge-success'
                                : autor.estado == 2
                                ? 'badge-warning'
                                : autor.estado == 3
                                ? 'badge-danger'
                                : ''
                            "
                            >{{ autor.nEstado }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-body" v-else>
                  <div class="info-box bg-gradient-warning m-0">
                    <span class="info-box-icon"
                      ><i class="fas fa-exclamation-triangle"></i
                    ></span>
                    <div class="info-box-content">
                      <span class="info-box-text">No tiene Autorizaciones</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Justificación-->
    <div
      class="modal fade"
      id="ModalJustificacion"
      tabindex="-1"
      role="dialog"
      style="z-index: 20000 !important"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h5 class="modal-title text-white">Justificación</h5>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ data_solicitud.justificacion }}
          </div>
          <div class="modal-footer p-0">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Observación -->
    <div
      class="modal fade"
      id="ModalObservacion"
      tabindex="-1"
      role="dialog"
      style="background-color: #00000082; z-index: 20000 !important"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h5 class="modal-title text-white">Observación</h5>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ data_solicitud.observaciones }}
          </div>
          <div class="modal-footer p-0">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "CsSolicitudesIntDetalle",
  components: {},
  data() {
    return {
      data_solicitud: {},
    };
  },
  methods: {
    llenar_modal(solicitud) {
      this.data_solicitud = solicitud;
      $("#modal-form-detalle-CsIntSolicitud .nav-link").removeClass("active");
      $("#tab-Solicitudes").addClass("active");
      $("#modal-form-detalle-CsIntSolicitud .tab-pane").removeClass("active");
      $("#Solicitudes").addClass("active");
    },
  },
};
</script>
